import React, {Fragment, useContext} from 'react'
import styled from 'styled-components'
import { ThemeContext } from '../../Context/ThemeContext'
import LaravelLogo from '../../Asset/laravel_logo.jpg'
import PhpLogo from '../../Asset/php_logo.jpg'
import GitHubLogo from '../../Asset/github_logo.jpg'
import MySqlLogo from '../../Asset/mysql_logo.jpg'
import ReactLogo from '../../Asset/react_logo.jpg'
import JavaLogo from '../../Asset/java-logo.jpg'
import SpringLogo from '../../Asset/spring-logo.jpg'
import JenkinsLogo from '../../Asset/jenkins-logo.jpg'
import AwsLogo from '../../Asset/aws-logo.jpg'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  text-align: ${(props) => props.align};
  margin: 30px 0;
  width: 100%;
  justify-content: space-around;
`

const Logo = styled.img`
  display: flex;
  flex: 1;
  object-fit: contain;
  max-width: 200px;
  @media (max-width: 1000px) {
    max-width: 100px;
  }

  @media (max-width: 500px) {
    max-width: 75px;
  }
`

const ImageStrip = ({ children }) => {
  const theme = useContext(ThemeContext)[0]

  return (
    <Fragment>
      <Container theme={theme}>
        <Logo src={LaravelLogo} />
        <Logo src={PhpLogo} />
        <Logo src={GitHubLogo} />
        <Logo src={MySqlLogo} />
        <Logo src={ReactLogo} />
      </Container>
      <Container theme={theme}>
        <Logo src={JavaLogo}/>
        <Logo src={SpringLogo}/>
        <Logo src={JenkinsLogo}/>
        <Logo src={AwsLogo}/>
      </Container>
    </Fragment>
  )
}

export default ImageStrip
