import React, { useContext } from 'react'
import styled from 'styled-components'
import { ThemeContext } from '../../Context/ThemeContext'

const Container = styled.div`
  background-color: ${(props) => props.theme.palette.darkBlue};
  color: ${(props) => props.theme.palette.gray};
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const InfoStrip = ({ children }) => {
  const theme = useContext(ThemeContext)[0]

  return <Container theme={theme}>{children}</Container>
}

export default InfoStrip
