import React, { useContext } from 'react'
import Wrapper from '../Components/Wrapper'
import styled from 'styled-components'
import HeaderImg from '../Asset/Get in touch Page header.jpeg'
import HeaderImgMobile from '../Asset/Get in touch Page header mobile.jpeg'
import { ThemeContext } from '../Context/ThemeContext'
import TextBox from '../Components/TextBox'
import useWindowSize from '../Hooks/useWindowSize'

const HeaderImage = styled.img`
  width: 100%;
`

const HeaderWrapper = styled.div`
  position: relative;
  top: 0;
  overflow: auto;
  background-color: ${(props) => props.theme.palette.primary};
`

const SubHeading = styled.h3`
  color: ${(props) => props.theme.text.h3};
  margin-bottom: 5px;
`

const TextBlock = styled.p`
  margin-top: 5px;
`

const ContactLink = styled.a`
  color: ${(props) => props.theme.link.regular};
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.link.hovered};
  }
`

const Contact = () => {
  const theme = useContext(ThemeContext)[0]
  const { width } = useWindowSize()

  return (
    <>
      <HeaderWrapper theme={theme}>
        <HeaderImage src={width > 768 ? HeaderImg : HeaderImgMobile} />
      </HeaderWrapper>
      <Wrapper>
        <TextBox align={'left'} subTitle={'Where to start'} />
        <SubHeading theme={theme}>
          Let’s arrange a “get to know each other" call
        </SubHeading>
        <TextBlock>
          My recommendation is definitely you to take advantage of the free
          consultation session that is available to you. It most likely will
          take place online on a video conferencing service of your choice.
        </TextBlock>
        <SubHeading theme={theme}>Send an email</SubHeading>
        <TextBlock>
          You can also send an email to{' '}
          <ContactLink
            rel="noreferrer"
            target="_blank"
            theme={theme}
            href={'mailto:hello@thecaringdeveloper.com'}
          >
            hello@thecaringdeveloper.com
          </ContactLink>
          . It will land on my phone that I keep on my desk during the day.
          Please allow me 48 hours to respond as I like “batch processing” my
          emails every other day.
        </TextBlock>
        <SubHeading theme={theme}>Determined? Give me a call!</SubHeading>
        <TextBlock>
          I reserve my dedicated phone number for urgent cases. If you are a
          client, you already have access to the client portal which is faster.
          If however you are sure you want to hire me for your project and it
          should start yesterday, I would invite you to give me a call on{' '}
          <ContactLink
            rel="noreferrer"
            target="_blank"
            theme={theme}
            href={'tel:00447923997286'}
          >
            +447923997286
          </ContactLink>
          . If I can’t pick it up immediately, I’ll call you back.
        </TextBlock>
        <SubHeading theme={theme}>
          In the Bristol area? How about a coffee?
        </SubHeading>
        <TextBlock>
          If you and I happen to be living in the same city (small world isn’t
          it? :) ), we might consider your consultation session in person (only
          when allowed) in a coffee shop. There are plenty great ones. Let me
          recommend the Cafe Refectoire.
        </TextBlock>
      </Wrapper>
    </>
  )
}

export default Contact
