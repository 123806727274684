import React, { useContext } from 'react'
import Wrapper from '../Components/Wrapper'
import styled from 'styled-components'
import HeaderImg from '../Asset/Mobile Apps Page header.jpeg'
import HeaderImgMobile from '../Asset/Mobile Apps.jpeg'
import { ThemeContext } from '../Context/ThemeContext'
import TextBox from '../Components/TextBox'
import useWindowSize from '../Hooks/useWindowSize'
import Diagram from '../Asset/diagram_mobile_app.jpg'

const HeaderImage = styled.img`
  width: 100%;
`

const DiagramImage = styled.img`
  max-width: 100%;
`

const HeaderWrapper = styled.div`
  position: relative;
  top: 0;
  overflow: auto;
  background-color: ${(props) => props.theme.palette.primary};
`

const SubHeading = styled.h3`
  color: ${(props) => props.theme.text.h3};
  margin-bottom: 5px;
`

const TextBlock = styled.p`
  margin-top: 5px;
`

const MobileApps = () => {
  const theme = useContext(ThemeContext)[0]
  const { width } = useWindowSize()

  return (
    <>
      <HeaderWrapper theme={theme}>
        <HeaderImage src={width > 768 ? HeaderImg : HeaderImgMobile} />
      </HeaderWrapper>
      <Wrapper>
        <TextBox subTitle={'Step up the game with your own mobile app!'} />
        <SubHeading theme={theme}>
          iOS, Android, Tablet, Mobile, App Store, Google Play - all in the
          basket
        </SubHeading>
        <TextBlock>
          It will look great, be fast and can have all the features that come to
          mind. I take care of the building and distribution for you through the
          app stores.
        </TextBlock>
        <SubHeading theme={theme}>What is cross platform?</SubHeading>
        <TextBlock>
          Cross-platform means that the app is only coded once in a way that it
          works on iOS and Android devices. React Native is my chosen tool to
          achieve this, comparing and trying out close to all the others. That
          includes Flutter, Xamarin and NativeScript. <br />I take care of the
          app being built and distributed through the app stores for you with
          lots of testing options along the way.
        </TextBlock>
        <SubHeading theme={theme}>Third parties and your API</SubHeading>
        <TextBlock>
          The apps can easily be hooked up with APIs and other services to be
          interactive. They are also beautiful and performant.
        </TextBlock>
        <TextBox>
          <DiagramImage src={Diagram} />
        </TextBox>
      </Wrapper>
    </>
  )
}

export default MobileApps
