import React from 'react'
import styled from 'styled-components'
import Footer from '../Partials/Footer'

// Styled components

const PageContainer = styled.section`
  left: 0;
  display: block;
  height: 100vh;
  width: 100%;
  background-color: #fff;
`

const ContentContainer = styled.div`
  background-color: white;
  padding: 0;
  margin: 0;
  position: relative;
`

const GeneralLayout = ({ children }) => {
  return (
    <PageContainer>
      <ContentContainer>{children}</ContentContainer>
      <Footer />
    </PageContainer>
  )
}

export default GeneralLayout
