import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import GeneralLayout from './Pages/Layout/GeneralLayout'
import Navigation from './Components/Navigation'
import { ThemeProvider } from './Context/ThemeContext'
import Home from './Pages/Home'
import Contact from './Pages/Contact'
import SimpleWebsites from './Pages/SimpleWebsites'
import Ecommerce from './Pages/Ecommerce'
import MobileApps from './Pages/MobileApps'
import Resume from './Pages/Resume'
import Articles from './Pages/Articles'
import PrivacyPolicy from './Pages/PrivacyPolicy'
import Videos from "./Pages/Videos";

const App = () => {
  return (
    <ThemeProvider>
      <Router>
        <Navigation />
        <GeneralLayout>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/services/e-commerce">
              <Ecommerce />
            </Route>
            <Route exact path="/services/simple-websites">
              <SimpleWebsites />
            </Route>
            <Route exact path="/services/mobile-apps">
              <MobileApps />
            </Route>
            <Route exact path="/resume">
              <Resume />
            </Route>
            <Route exact path="/articles">
              <Articles />
            </Route>
            <Route exact path="/get-in-touch">
              <Contact />
            </Route>
            <Route exact path="/videos">
              <Videos />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route>
              <Home />
            </Route>
          </Switch>
        </GeneralLayout>
      </Router>
    </ThemeProvider>
  )
}

export default App
