import React, { useContext } from 'react'
import Wrapper from '../Components/Wrapper'
import styled from 'styled-components'
import HeaderImg from '../Asset/E-Commerce Page header.jpeg'
import HeaderImgMobile from '../Asset/E-Commerce.jpeg'
import { ThemeContext } from '../Context/ThemeContext'
import TextBox from '../Components/TextBox'
import useWindowSize from '../Hooks/useWindowSize'
import Diagram from '../Asset/diagram_ecommerce.jpg'

const HeaderImage = styled.img`
  width: 100%;
`

const DiagramImage = styled.img`
  max-width: 100%;
`

const HeaderWrapper = styled.div`
  position: relative;
  top: 0;
  overflow: auto;
  background-color: ${(props) => props.theme.palette.primary};
`

const SubHeading = styled.h3`
  color: ${(props) => props.theme.text.h3};
  margin-bottom: 5px;
`

const TextBlock = styled.p`
  margin-top: 5px;
`

const ListItem = styled.li`
  margin: 10px 0;
`

const Ecommerce = () => {
  const theme = useContext(ThemeContext)[0]
  const { width } = useWindowSize()

  return (
    <>
      <HeaderWrapper theme={theme}>
        <HeaderImage src={width > 768 ? HeaderImg : HeaderImgMobile} />
      </HeaderWrapper>
      <Wrapper>
        <TextBox align={'left'} subTitle={'Sell online!'} />
        <SubHeading theme={theme}>
          The problem with the “one size fits all” frameworks
        </SubHeading>
        <TextBlock>
          The common e-commerce platforms are limited one way or another.
          WooCommerce for example is slow, most of its advanced plugins are
          costly and due to the frequent update cycle of the underlying
          framework, it needs regular attention.
        </TextBlock>
        <SubHeading theme={theme}>The better way</SubHeading>
        <TextBlock>
          Let’s see what I can build for you: The stack consists of 3
          applications (yes, read it right). These are the front-end React app,
          the admin React app and a Laravel core API.
        </TextBlock>
        <SubHeading theme={theme}>
          What does each component do and what are the benefits?
        </SubHeading>
        <TextBlock>
          <ul>
            <ListItem>
              <strong>The Core API</strong>
              <br />
              Everything that needs to be stored, requires authentication or
              talks to third-party services (such as payment gateways) is so
              called server-side software. To talk to a server side application,
              the most common way is a REST API. It checks if the user’s
              identity, the source of the request and if all is fine, replies
              with the data that was asked for. It also does all the heavy
              lifting, such as sending emails, generating reports or invoices,
              sending out push notifications and the list goes on. What it also
              allows for is the data consumed by different platforms, like a
              mobile application. Yes, it requires no server side development
              whatsoever to use an existing API from a mobile app.
            </ListItem>
            <strong>The Front-End React app</strong>
            <br />I just love React. It is flexible, fast and powerful. I have
            been building React applications for a rather long time and it still
            surprises me how cool it is. The front-end application is a
            standalone app that talks to the Core API. When a user performs a
            product search or logs into their account, it sends a request to the
            server through the API and once the data is back, it is displayed or
            used by the front-end. The main benefit though is that React
            applications can be beautiful, interactive, powerful and most of all
            extremely fast.
            <ListItem>
              <strong>The Admin React App</strong>
              <br />
              For those running the show, there is a place where they can see
              and pull all the levers. This is an admin application also built
              in React. It is very similar to its the front-end counterpart in
              terms of how it works. The difference is of course in what it
              does.
            </ListItem>
          </ul>
          These components work seamlessly with each other and can be
          dynamically aligned to your exact needs. There are no limitations.{' '}
        </TextBlock>
        <TextBox>
          <DiagramImage src={Diagram} />
        </TextBox>
      </Wrapper>
    </>
  )
}

export default Ecommerce
