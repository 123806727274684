import React, { useContext } from 'react'
import Wrapper from '../Components/Wrapper'
import styled from 'styled-components'
import HeaderImg from '../Asset/Videos Page header.jpeg'
import HeaderImgMobile from '../Asset/Videos Page header mobile.jpeg'
import CiCdTutorialSeries from '../Asset/CICD-tutorial-series.jpg'
import { ThemeContext } from '../Context/ThemeContext'
import TextBox from '../Components/TextBox'
import useWindowSize from '../Hooks/useWindowSize'
import WebDevVideoThumbnail from '../Asset/howtobecomewebdevthumbnail.jpg'

const HeaderImage = styled.img`
  width: 100%;
`

const DiagramImage = styled.img`
  max-width: 100%;
`

const HeaderWrapper = styled.div`
  position: relative;
  top: 0;
  overflow: auto;
  background-color: ${(props) => props.theme.palette.primary};
`

const SubHeading = styled.h3`
  color: ${(props) => props.theme.text.h3};
  margin-bottom: 5px;
`

const TextBlock = styled.p`
  margin-top: 5px;
`

const ListItem = styled.li`
  margin: 10px 0;
`

const Image = styled.img`
  max-width: 400px;
  width: 100%;
`

const Link = styled.a`
  color: ${(props) => props.theme.link.regular};
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.link.hovered};
  }
`

const Videos = () => {
  const theme = useContext(ThemeContext)[0]
  const { width } = useWindowSize()

  return (
    <>
      <HeaderWrapper theme={theme}>
        <HeaderImage src={width > 768 ? HeaderImg : HeaderImgMobile} />
      </HeaderWrapper>
      <Wrapper>
        <TextBox
          align={'left'}
          subTitle={'Sharing is caring'}
        />
        <SubHeading theme={theme}>
          I like to share useful knowledge
        </SubHeading>
        <TextBlock>
          When I face a complex task that I don't find comprehensive guide on on-line, I tend to write an article or record videos, so fellow devs can use it.
        </TextBlock>
        <SubHeading theme={theme}>How to become a web developer in 2023</SubHeading>
        <Link
          rel="noreferrer"
          target="_blank"
          theme={theme}
          href={'https://youtu.be/jNIbUpuoOM8'}
        >
        <Image src={WebDevVideoThumbnail}></Image>
        </Link>
        <br/>
        <Link
          rel="noreferrer"
          target="_blank"
          theme={theme}
          href={'https://youtu.be/jNIbUpuoOM8'}
        >Watch on YouTube</Link>
        <hr/>
        <SubHeading theme={theme}>Laravel Application CI/CD from scratch using Docker, Jenkins and AWS</SubHeading>
        <Image src={CiCdTutorialSeries}></Image>
        <br/>
        <Link
          rel="noreferrer"
          target="_blank"
          theme={theme}
          href={'https://www.youtube.com/playlist?list=PLSe91q1rf5vV-gTi0zJTz1BNvnb0x426p'}
        >Watch on YouTube</Link>
        <TextBlock>
          The 12 part tutorial series walks you through all steps of setting up CI/CD pipelines for your apps using only open source and platform agnostic tools such as Jenkins and Docker.
          Using the techniques explained in the videos, significant time can be saved on deployments. Additional benefits are reduced (or even eliminated) downtime and faster turnaround cycle during development.
        </TextBlock>
      </Wrapper>
    </>
  )
}

export default Videos
