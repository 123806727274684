import React, { useContext } from 'react'
import Wrapper from '../Components/Wrapper'
import styled from 'styled-components'
import HeaderImg from '../Asset/Simple websites Page header.jpeg'
import HeaderImgMobile from '../Asset/Simple websites.jpeg'
import { ThemeContext } from '../Context/ThemeContext'
import TextBox from '../Components/TextBox'
import useWindowSize from '../Hooks/useWindowSize'
import Diagram from '../Asset/diagram_simple.jpg'

const HeaderImage = styled.img`
  width: 100%;
`

const DiagramImage = styled.img`
  max-width: 100%;
`

const HeaderWrapper = styled.div`
  position: relative;
  top: 0;
  overflow: auto;
  background-color: ${(props) => props.theme.palette.primary};
`

const SubHeading = styled.h3`
  color: ${(props) => props.theme.text.h3};
  margin-bottom: 5px;
`

const TextBlock = styled.p`
  margin-top: 5px;
`

const ListItem = styled.li`
  margin: 10px 0;
`

const SimpleWebsites = () => {
  const theme = useContext(ThemeContext)[0]
  const { width } = useWindowSize()

  return (
    <>
      <HeaderWrapper theme={theme}>
        <HeaderImage src={width > 768 ? HeaderImg : HeaderImgMobile} />
      </HeaderWrapper>
      <Wrapper>
        <TextBox
          align={'left'}
          subTitle={'Simplicity means speed in every way'}
        />
        <SubHeading theme={theme}>
          What qualifies as a simple website
        </SubHeading>
        <TextBlock>
          These are the sites that need to look pretty, tell a story and no need
          for an all bells and whistles admin area and a million control
          options. I use a technology called <strong>serverless React</strong>{' '}
          for this. It means that there is no back-end or CMS that would slow
          the thing down. The main benefits are speed and cost.
          <TextBlock></TextBlock>A site like this can be made available in only
          a few days if you have the content and some design guidelines ready.
          The actual coding part is blazingly fast, yet the site looks and
          performs absolutely stunningly. It is also very cheap to host. These
          sites take so little processing power, that they can be hosted on a
          Raspberry PI while still remaining performant within reason.
        </TextBlock>
        <SubHeading theme={theme}>They can be interactive!</SubHeading>
        <TextBlock>
          Being simple and serverless does not mean it can’t do advanced things.
          It is simply that cloud services do all the heavy lifting to support
          advanced functionality such as authentication, database or emails.
        </TextBlock>
        <SubHeading theme={theme}>Common use cases</SubHeading>
        <TextBlock>
          To give you an idea, here is a list of what a website of this kind is
          the most designed to achieve:
        </TextBlock>
        <TextBlock>
          <ul>
            <ListItem>
              <strong>Rapid prototyping</strong>
              <br />
              Let&apos;s say you have an idea and you&apos;d like to test it as
              quick and effective as possible without burning plenty of cash.
            </ListItem>
            <strong>MVP</strong>
            <br />
            Similarly, you may want to add the core features in a simplified way
            so your customers get the idea and can interact with your product.
            <ListItem>
              <strong>Customer surveys</strong>
              <br />
              Gather feedback or opinion from he masses.
            </ListItem>
            <ListItem>
              <strong>Product pages</strong>
              <br />
              Have a book, course or a record to sell? A dedicated page can do
              wonders in sales!
            </ListItem>
            <ListItem>
              <strong>Single page applications</strong>
              <br />
              It is a growing trend to promote your business through a simle
              single page application that is lightweight, fast and very easy to
              maintain.
            </ListItem>
          </ul>
        </TextBlock>
        <SubHeading theme={theme}>How fast is fast?</SubHeading>
        <TextBlock>
          When I say fast, I mean days. If you have a design and content ready
          in let’s say Photoshop or Adobe XD, I can build it for you in a single
          sitting. That’s the most ideal case, so let’s see a not so ideal: if
          you have an idea or a service you’d like to promote, you are more than
          welcome to discuss, so I can reach out to my circles to help you out
          with content writing and design while when I can take care of the
          coding when it gets to this stage.
        </TextBlock>
        <TextBox>
          <DiagramImage src={Diagram} />
        </TextBox>
      </Wrapper>
    </>
  )
}

export default SimpleWebsites
