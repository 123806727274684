import React, { useContext } from 'react'
import styled from 'styled-components'
import { ThemeContext } from '../Context/ThemeContext'

const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: ${(props) => props.align};
  margin-left: ${(props) => props.gutter * 10 + 'px'};
  margin-right: ${(props) => props.gutter * 10 + 'px'};

  @media (max-width: 768px) {
    margin: 0;
  }
`

const Title = styled.h1`
  color: ${(props) => props.theme.text.h1};
  font-weight: 600;
  text-align: ${(props) => props.align};
  font-family: Helvetica;
  font-size: ${(props) => props.size + 'px'};
  line-height: ${(props) => props.size * 1.6 + 'px'};
  margin: 25px 0 5px 0;
  @media (max-width: 768px) {
    font-size: 35px;
    text-align: left;
  }
`

const SubTitle = styled.h2`
  color: ${(props) => props.theme.text.h2};
  font-weight: 600;
  text-align: ${(props) => props.align};
  font-family: Helvetica;
  font-size: ${(props) => props.size + 'px'};
  line-height: ${(props) => props.size * 1.4 + 'px'};
  margin: 25px 0 0px 0;
  @media (max-width: 768px) {
    font-size: 30px;
    text-align: left;
  }
`

const Text = styled.div`
  color: ${(props) => props.theme.text.p};
  font-weight: 200;
  text-align: ${(props) => props.align};
  font-size: ${(props) => props.size + 'px'};
  line-height: ${(props) => props.size * 1.6 + 'px'};
  font-family: Helvetica;
  @media (max-width: 768px) {
    text-align: left;
    font-weight: 500;
  }
`

const TextBox = ({
  title,
  subTitle,
  align = 'center',
  children,
  size = 'large',
  gutter = 1
}) => {
  const theme = useContext(ThemeContext)[0]

  return (
    <Container gutter={gutter} align={align} theme={theme}>
      {title && (
        <Title align={align} size={size === 'large' ? 45 : 25} theme={theme}>
          {title}
        </Title>
      )}
      {subTitle && (
        <SubTitle align={align} size={size === 'large' ? 35 : 20} theme={theme}>
          {subTitle}
        </SubTitle>
      )}
      <Text align={align} size={size === 'large' ? 18 : 14} theme={theme}>
        {children}
      </Text>
    </Container>
  )
}

export default TextBox
