import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../Components/Wrapper'
import styled from 'styled-components'
import HeaderImg from '../Asset/Resume page header.jpeg'
import HeaderImgMobile from '../Asset/Resume page header mobile.jpeg'
import AppStoreIcon from '../Asset/app-store.png'
import GooglePlayIcon from '../Asset/google-play.png'
import { ThemeContext } from '../Context/ThemeContext'
import TextBox from '../Components/TextBox'
import useWindowSize from '../Hooks/useWindowSize'
import ImageViewer from 'react-simple-image-viewer'
import { Collections } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import { HashLink } from 'react-router-hash-link'

const HeaderImage = styled.img`
  width: 100%;
`

const HeaderWrapper = styled.div`
  position: relative;
  top: 0;
  overflow: auto;
  background-color: ${(props) => props.theme.palette.primary};
`

const SubHeading = styled.h3`
  color: ${(props) => props.theme.text.h3};
  margin-bottom: 5px;
`

const TextBlock = styled.p`
  margin-top: 5px;
`

const ListItem = styled.li`
  margin: 10px 0;
`

const ExternalLink = styled.a`
  color: ${(props) => props.theme.link.regular};
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.link.hovered};
  }
`

const StyledAppstoreIconImage = styled('img')`
  height: 30px;
  margin: 5px;
`

const Link = styled.a`
  color: ${(props) => props.theme.link.regular};
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.link.hovered};
  }
`

const StyledHashLink = styled(HashLink)`
  color: ${(props) => props.theme.link.regular};
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.link.hovered};
  }
`

const Ecommerce = () => {
  const theme = useContext(ThemeContext)[0]
  const { width } = useWindowSize()
  const [galleryId, setGalleryId] = useState(0)
  const [currentImage, setCurrentImage] = useState(0)
  const [images, setImages] = useState([])

  const closeGallery = () => {
    setGalleryId(0)
    setCurrentImage(0)
  }

  useEffect(() => {
    if (galleryId !== 0) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
    let images = []
    switch (galleryId) {
      case 1:
        images = [
          require('../Asset/samples/samples-013.jpg'),
          require('../Asset/samples/samples-014.jpg')
        ]
        break
      case 2:
        images = [
          require('../Asset/samples/samples-015.jpg'),
          require('../Asset/samples/samples-016.jpg'),
          require('../Asset/samples/samples-017.jpg'),
          require('../Asset/samples/samples-018.jpg')
        ]
        break
      case 3:
        images = [
          require('../Asset/samples/phone_screenshot_mg.png'),
          require('../Asset/samples/phone_reports_1.jpeg'),
          require('../Asset/samples/phone_reports_1.jpeg'),
          require('../Asset/samples/macbook_screenshot_dashboard.png'),
          require('../Asset/samples/samples-007.jpg'),
          require('../Asset/samples/samples-008.jpg')
        ]
        break
      case 4:
        images = [
          require('../Asset/samples/samples-004.jpg'),
          require('../Asset/samples/samples-005.jpg'),
          require('../Asset/samples/samples-006.jpg')
        ]
        break
      case 5:
        images = [
          require('../Asset/samples/samples-009.jpg'),
          require('../Asset/samples/samples-010.jpg')
        ]
        break
      case 6:
        images = [
          require('../Asset/samples/samples-001.jpg'),
          require('../Asset/samples/samples-011.jpg'),
          require('../Asset/samples/samples-012.jpg')
        ]
        break
    }
    setImages(images.map((item) => item.default))
  }, [galleryId])

  return (
    <>
      <HeaderWrapper theme={theme}>
        <HeaderImage src={width > 768 ? HeaderImg : HeaderImgMobile} />
      </HeaderWrapper>
      <Wrapper>
        {galleryId !== 0 && images.length > 0 && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            onClose={closeGallery}
            backgroundStyle={{
              height: '100vh',
              position: 'fixed',
              zIndex: 100,
              backgroundColor: 'rgba(0,0,0,0.9)'
            }}
          />
        )}
        <TextBox align={'left'} subTitle={'Overview'}>
          <SubHeading theme={theme}>Professional developer for over 9 years</SubHeading>
          <TextBlock>
            I have been working as a software engineer in various teams or alone as a freelancer or contractor since 2013. Besides coding I have interest in design (Adobe CC power user) as well as CAD engineering.
          </TextBlock>
          <SubHeading theme={theme}>Full time jobs</SubHeading>
          <TextBlock>
            I have worked for various firms including industry leaders such as
            LOQBOX and Worktribe to name a few. Exposure to the fintech and higher
            education industries was a game changer in my professional career.
            <ul>
              <li>2021: Media Exchange Group - Tech Lead and DevOps engineer</li>
              <li>Mar 2020 - Mar 2021: Worktribe - Senior web and mobile application developer</li>
              <li>Nov 2018 - Feb 2020: LOQBOX Savings - Back-end developer</li>
              <li>Oct 2016 - Sept 2018 Opal Technology International - Web and Android Developer</li>
            </ul>
          </TextBlock>
          <SubHeading theme={theme}>Self-education and 150 books</SubHeading>
          <TextBlock>
            Since 2016, I have made reading my daily habit along with listening
            to audio books. It has resulted over 150 titles having read or listened to
            since. My most favourite topics are: Business, Marketing,
            Philosophy, Technology and anything about how our brains work
          </TextBlock>
          <SubHeading id={'projects'} theme={theme}>
            Significant projects
          </SubHeading>
          <TextBlock>
            Through side projects my professional development received significant extra boost and exposure to a wide range of technologies
          </TextBlock>
          <TextBlock>Some of the projects I worked on</TextBlock>
          <ul>
            <ListItem>
              2022: <strong>MoneyGuard Finance Tracker</strong><small><em> Spring MVC | React Native | Spring Security | Project Management | Database Design | App Store Deployment</em></small>
              <br />The idea of a fully flexible personal finance mobile application was born many years ago. MoneyGuard even had a first iteration released in 2015 only for Android at the time.
              <br />This second iteration has been completely recreated from scratch so it is much more robust, more stable and works on both major platforms. <br />
              <br />
              <strong>The Backend</strong>
              <ul>
                <li>Written in Java using Spring MVC</li>
                <li>Open source</li>
              </ul>
              <ExternalLink
                rel="noreferrer"
                target={'_blank'}
                theme={theme}
                href={'https://github.com/rolandtreiber/moneyguard'}
              >
                See it on GitHub
              </ExternalLink>
              <br />
              <br />
              <strong>The Mobile App</strong>
              <ul>
                <li>Made with React Native</li>
                <li>Open source</li>
              </ul>
              <ExternalLink
                rel="noreferrer"
                target={'_blank'}
                theme={theme}
                href={'https://github.com/rolandtreiber/monayguard-mobile-app'}
              >
                See it on GitHub
              </ExternalLink>
              <br />
              <a rel="noreferrer" target={"_blank"} href={"https://apps.apple.com/us/app/moneyguard-finance-tracker/id6443543979"}><StyledAppstoreIconImage src={AppStoreIcon}/></a>
              {/*<a target={"_blank"} href={"https://play.google.com/store/apps/details?id=com.syncbin&hl=en&gl=US"}><StyledAppstoreIconImage src={GooglePlayIcon}/></a>*/}

            </ListItem>
            <ListItem>
              2021: <strong>SyncBin</strong><small><em> React | Google Firebase | Idea, planning and execution</em></small>
              <br />I have identified a pain point in moving simple text and files between 2 devices that
              may be phisically next to each other but don’t share the same architecture, operating system, form factor or brand.
              SyncBin is very easy to use. You simply open the same session on any number of devices you like. Once you start typing on one, it’ll appear real-time on all.
              File upload work the same way. You simply drop a file onto the upload area and once uploaded, it is available to download on all other devices.
              <br />
              <ExternalLink
                rel="noreferrer"
                target={'_blank'}
                theme={theme}
                href={'https://syncbin.co.uk'}
              >
                https://syncbin.co.uk
              </ExternalLink>
              <br />
              <a rel="noreferrer" target={"_blank"} href={"https://apps.apple.com/us/app/syncbin/id1629902171"}><StyledAppstoreIconImage src={AppStoreIcon}/></a>
              <a rel="noreferrer" target={"_blank"} href={"https://play.google.com/store/apps/details?id=com.syncbin&hl=en&gl=US"}><StyledAppstoreIconImage src={GooglePlayIcon}/></a>

            </ListItem>
            <ListItem>
              2021-22: <strong>Shoptopus</strong><small><em> Laravel | React | oAuth2 | Project Management | Database Design | Project lead</em></small>
              <br />A powerful and robust fully featured e-commerce system based on Laravel and React.
              <br />It is a joint venture worked on together with <Link href="https://istvanlovas.co.uk/" rel="noreferrer" target="_blank" theme={theme}>Istvan Lovas</Link>
              <br />Shoptopus is in active development and expected to hit the market in 2023
              <br />
              <strong>Non-comprehensive features list</strong>
              <ul>
                <li>Variable products</li>
                <li>Fully configurable product categories with unlimited nesting options</li>
                <li>Configurable product tags</li>
                <li>Bulk editing</li>
                <li>Automatic invoice generation including product images</li>
                <li>Import and export capabilities</li>
                <li>Roles and permissions</li>
                <li>Automatic comprehensive auditing data</li>
                <li>Full storefront customization</li>
                <li>CI/CD</li>
                <li>Rich reporting and data visualization options</li>
                <li>Rich media support (images, videos, data sheets etc)</li>
                <li>Advanced configurable delivery method support</li>
                <li>Advanced configurable discount and voucher support</li>
                <li>Above 90% test coverage (625 tests at the time of writing)</li>
              </ul>
              <br />

            </ListItem>
            <ListItem>
              2021: <strong>Cloo (coming soon)</strong><small><em> PHP | Laravel | React | React Native | Planning | Database design | Project management</em></small>
              <br />
              Cloo is an outdoor escape game that people can play in teams. It
              has a wide range of advanced feats.
              <br />
              To name a few:
              <ul>
                <li>Own socket server</li>
                <li>Cross-Platform mobile app</li>
                <li>Multiple payment cards support</li>
                <li>Customized news feed based on physical location</li>
                <li>Powerful game editor to craft and convert storylines to games</li>
                <li>Internal game review engine</li>
                <li>Microservice architecture</li>
              </ul>
            </ListItem>
            <ListItem>
              2020: <strong>Magyarshop</strong><small><em> PHP | Laravel | React | Planning | Database design | Project management | Maintenance</em></small>
              <br />I was approached to develop a full e-commerce solution from
              the ground up. Among the requests there were configurable
              products, full multi language support, multiple payment options,
              automated invoicing and delivery tiers based on distance, order
              weight and location. I offered a solution where I’d use Laravel 7
              server side and a React application for the client and admin
              areas. It is fully featured with oAuth2 authentication and many
              more features. The store was completed in just 4 months and enjoys
              great success to this day.
              <br />
              <ExternalLink
                rel="noreferrer"
                target={'_blank'}
                theme={theme}
                href={'https://magyarshop.co.uk'}
              >
                https://magyarshop.co.uk
              </ExternalLink>
              <br />
              <Button
                onClick={() => setGalleryId(6)}
                startIcon={<Collections />}
                variant="contained"
              >
                Gallery
              </Button>
            </ListItem>
            <ListItem>
              2020: <strong>Dtrans</strong><small><em> PHP | Laravel | VueJs | Planning | Database design | Project management</em></small>
              <br />I was hired for a freelance project to build a portal for a
              delivery company. The request was that it should have a fully
              automated quoting engine. I have offered a solution where the user
              can get a quick quote by entering only a few details and if they
              like it, they can add all other details in a 4 steps journey to
              order the delivery.
              <br />
              <ExternalLink
                rel="noreferrer"
                target={'_blank'}
                theme={theme}
                href={'https://dtrans.co.uk'}
              >
                https://dtrans.co.uk
              </ExternalLink>
              <br />
              <Button
                onClick={() => setGalleryId(5)}
                startIcon={<Collections />}
                variant="contained"
              >
                Gallery
              </Button>
            </ListItem>

            <ListItem>
              2019: <strong>Share & Ride</strong><small><em> Slim 3 | VueJs | Planning | Database design | Project management</em></small>
              <br />
              Share & Ride is a taxi sharing service. Unfortunately it is close
              to be completely inactive due to Covid 19. The main idea is that
              people can book taxi rides that are publicly posted on a board, so
              others can join in and take the remaining seats in the vehicle. It
              is designed in a way that additional stops can be requested as
              well as people are issued automatic partial refunds if someone
              joins in with them and their ride splits between more people.
              <br />
              <ExternalLink
                rel="noreferrer"
                target={'_blank'}
                theme={theme}
                href={'https://shareandride.co.uk'}
              >
                https://shareandride.co.uk
              </ExternalLink>
              <br />
              <Button
                onClick={() => setGalleryId(4)}
                startIcon={<Collections />}
                variant="contained"
              >
                Gallery
              </Button>
            </ListItem>

            <ListItem>
              2017: <strong>Gloucester Ski and Snowboard Centre website</strong><small><em> PHP | Laravel | VueJs | Planning | Database design | Project management</em></small>
              <br />
              The centre had an ageing website that was not performing up to the
              expectations. I created a site using Laravel and VueJs to handle
              all administrative tasks as well as serving the front-end to the
              users. The site enjoyed great success, however due to lack of maintenance
              it became obsolete and was replaced in 2022.
              <br />
              <Button
                onClick={() => setGalleryId(2)}
                startIcon={<Collections />}
                variant="contained"
              >
                Gallery
              </Button>
            </ListItem>
            <ListItem>
              2016: <strong>Asda and Lenstec Accessory ordering portal</strong><small><em> PHP | Laravel | Database design | Project management</em></small>
              <br />I was presented with a functional specification to kick the
              project off. Following this, I have presented my ideas using
              Laravel 5 and VieJs and a brief overview of how will it be built,
              what to expect, how to test and how will it be deployed. Once it
              was accepted, I designed, built and deployed the application. It
              is in use and is working as expected.
              <br />
              <Button
                onClick={() => setGalleryId(1)}
                startIcon={<Collections />}
                variant="contained"
              >
                Gallery
              </Button>
            </ListItem>
          </ul>
          <SubHeading theme={theme}>Expertise and tech</SubHeading>
          <TextBlock>
            I am working on my skills every single day. That said, I have
            invested so far the most time in becoming very good at{' '}
            <strong>Java and Spring MVC</strong>, <strong>Laravel</strong>,<strong>React</strong> and{' '}
            <strong>React Native</strong>. It would be a really long list to
            gather every tool and service I consider myself an advanced user of.
            To keep it short therefore, you can expect me to be able to use and
            respect modern standards with regard to how to write code
            effectively, collaborate version control, set up and manage servers,
            deploy and support software.
          </TextBlock>
          <TextBlock>
            I also like design and being a pro user in{' '}
            <strong>Adobe Creative Cloud</strong>. It comes very handy doing
            simple image editing, exporting an icon or mock up a layout for a
            mobile app as examples.
          </TextBlock>
          <SubHeading theme={theme}>Publications</SubHeading>
          <TextBlock>
            I write articles on <StyledHashLink theme={theme} smooth to={'/articles'}>Medium</StyledHashLink> and recording <StyledHashLink theme={theme} smooth to={'/videos'}>videos</StyledHashLink> to share knowledge.{' '}
          </TextBlock>
        </TextBox>
      </Wrapper>
    </>
  )
}

export default Ecommerce
