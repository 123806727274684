import React, { useContext } from 'react'
import Wrapper from '../Components/Wrapper'
import styled from 'styled-components'
import HeaderImg from '../Asset/Articles Page header.jpeg'
import HeaderImgMobile from '../Asset/Articles Page header mobile.jpeg'
import { ThemeContext } from '../Context/ThemeContext'
import useWindowSize from '../Hooks/useWindowSize'
import HomeServerArticleImage from '../Asset/Homeserver_article.jpeg'
import CmsArticleImage from '../Asset/CMS_article.jpeg'
import IconFontArticleImage from '../Asset/Icon_font_article.jpeg'
import BottomTabArticleImage from '../Asset/Bottom_tab_navigator_animation_article.jpeg'
import AvoidComplacencyArticle from '../Asset/how_to_avoid_complancency_as_a_developer.jpeg'

const HeaderImage = styled.img`
  width: 100%;
`

const HeaderWrapper = styled.div`
  position: relative;
  top: 0;
  overflow: auto;
  background-color: ${(props) => props.theme.palette.primary};
`

const SubHeading = styled.h3`
  color: ${(props) => props.theme.text.h3};
  margin-bottom: 5px;
`

const TextBlock = styled.p`
  margin-top: 5px;
`

const Link = styled.a`
  color: ${(props) => props.theme.link.regular};
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.link.hovered};
  }
`

const Image = styled.img`
  max-width: 400px;
  width: 100%;
`

const Articles = () => {
  const theme = useContext(ThemeContext)[0]
  const { width } = useWindowSize()

  return (
    <>
      <HeaderWrapper theme={theme}>
        <HeaderImage src={width > 768 ? HeaderImg : HeaderImgMobile} />
      </HeaderWrapper>
      <Wrapper>
        <SubHeading theme={theme}>
          How to host your website on an old Mac mini with SSL
        </SubHeading>
        <Image src={HomeServerArticleImage}></Image>
        <TextBlock>
          Hello there. For a while I contemplated the idea of hosting my website on my home server. Among the reasons were curiosity, ease of making changes in the environment setup, full access and the learning experience...
        </TextBlock>
        <Link
          rel="noreferrer"
          target="_blank"
          theme={theme}
          href={'https://medium.com/geekculture/how-to-host-your-website-on-an-old-mac-mini-with-ssl-4f7c8125cc8c'}
        >Continue Reading on Medium.com</Link>
        <SubHeading theme={theme}>
          The myth of CMS systems and when should you avoid them
        </SubHeading>
        <Image src={CmsArticleImage}></Image>
        <TextBlock>
          Valuable insights learned through over 6 years of software development and working with clients of all sizes will follow to help you in finding the best approach to get your website up and running.
        </TextBlock>
        <Link
          rel="noreferrer"
          target="_blank"
          theme={theme}
          href={'https://hello-85764.medium.com/the-myth-of-cms-systems-and-when-should-you-avoid-them-2ee8ef5c934e'}
        >Continue Reading on Medium.com</Link>
        <SubHeading theme={theme}>
          How to create and use custom icon font in React Native
        </SubHeading>
        <Image src={IconFontArticleImage}></Image>
        <TextBlock>
          Recently I have had to implement a very unique icon set in a React Native app and wired it up with dark mode and colour themes. It got me thinking if fellow developers would be interested in my way of getting around this.
        </TextBlock>
        <Link
          rel="noreferrer"
          target="_blank"
          theme={theme}
          href={'https://hello-85764.medium.com/how-to-create-and-use-custom-icon-font-in-react-native-24ef3b1c8f65'}
        >Continue Reading on Medium.com</Link>
        <SubHeading theme={theme}>
          Animating the bottom tab navigator in React Native in 2021
        </SubHeading>
        <Image src={BottomTabArticleImage}></Image>
        <TextBlock>
          In this tutorial you will learn how flexible the bottom tab navigation can be in React Native. You can customise more than it seems at first. We will focus on the custom animations to interpolate colour, change size and position of the selected tab.
        </TextBlock>
        <Link
          rel="noreferrer"
          target="_blank"
          theme={theme}
          href={'https://hello-85764.medium.com/animating-the-bottom-tab-navigator-in-react-native-in-2021-cb3d5dbd39ce'}
        >Continue Reading on Medium.com</Link>
        <SubHeading theme={theme}>
          How to avoid complacency as a developer
        </SubHeading>
        <Image src={AvoidComplacencyArticle}></Image>
        <TextBlock>
          Do you remember the first days of your journey as a coding professional? Regardless your field, I bet you were fascinated by the endless possibilities of what you can build. It was most likely followed by years of hard work to master your weaponry. By this, comes however the threat of using more and more of what you already know and learn new techniques less frequently as in the early days. Here comes my advice of how to stay on top of your game for years if not decades.
        </TextBlock>
        <Link
          rel="noreferrer"
          target="_blank"
          theme={theme}
          href={'https://hello-85764.medium.com/how-to-avoid-complacency-as-a-developer-8593a565a552'}
        >Continue Reading on Medium.com</Link>
      </Wrapper>
    </>
  )
}

export default Articles
