import React, { useContext } from 'react'
import styled from 'styled-components'
import { ThemeContext } from '../../Context/ThemeContext'
import AuthorImage from '../../Asset/author.jpg'
import TextBox from '../../Components/TextBox'

const Container = styled.div`
  color: ${(props) => props.theme.palette.gray};
  padding: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Avatar = styled.img`
  border-radius: 50%;
  flex: 1;
  max-width: 200px;
  object-fit: contain;
  vertical-align: center;
  @media (max-width: 768px) {
    max-width: 150px;
  }
`

const Title = styled.h1`
  color: ${(props) => props.theme.text.h1};
  font-weight: 600;
  text-align: ${(props) => props.align};
  font-family: Helvetica;
  font-size: ${(props) => props.size + 'px'};
  line-height: ${(props) => props.size * 1.6 + 'px'};
  margin: 0 0 5px 0;
  @media (max-width: 768px) {
    font-size: 35px;
    text-align: left;
  }
`

const Author = () => {
  const theme = useContext(ThemeContext)[0]

  return (
    <Container theme={theme}>
      <TextBox align={'center'} theme={theme}>
        <Title size={25} theme={theme}>
          The Caring Developer
        </Title>
        <p style={{ marginBottom: 30 }}>
          I have spent the last 7 years in a corporate environment in various
          software teams. During this time, I also worked on side projects most
          of the time. It meant early morning coding sessions from as early as
          4am till my day job started. Through my projects, I could steer my
          professional development better and was exposed to a much wider range
          of technologies.
        </p>
        <p>
          Besides, I have also constantly worked with entrepreneurs and got into
          the mindset of discussing ideas, finding solutions, working closely
          with founders and accurately plan ahead.
        </p>
        <p>
          Most of all, I loved it much more than my 9-5, so I decided to
          dedicate my professional life to this and make it available for those
          who need it the most.
        </p>
        <Avatar src={AuthorImage} />
      </TextBox>
    </Container>
  )
}

export default Author
