import React, { useContext, useState } from 'react'
import { ThemeContext } from '../Context/ThemeContext'
import styled from 'styled-components'
import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Hidden
} from '@material-ui/core'
import {
  Mail,
  Menu,
  House,
  PhoneIphone,
  Devices,
  ShoppingCart,
  Description,
  Code,
  YouTube,
  Assignment
} from '@material-ui/icons'
import { HashLink as Link } from 'react-router-hash-link'
import Home from '../Pages/Home'
import Contact from '../Pages/Contact'
import LogoImage from '../Asset/logo_tcd.png'
import { makeStyles } from '@material-ui/core/styles'
import Resume from '../Pages/Resume'
import Articles from '../Pages/Articles'
import useScrollPosition from '@react-hook/window-scroll'
import Videos from "../Pages/Videos";

// Styled components

const Logo = styled.img`
  max-width: 400px;
  width: 40%;
  padding: 20px 20px 10px 20px;
  display: inline-block;
  align-self: center;
`

const NavItem = styled(Link)`
  padding: 10px;
`

const AppTopBar = styled.section`
  background-color: ${(props) => props.theme.navigation.background};
  overflow: auto;
  position: ${(props) => props.position};
  top: 0;
  display: flex;
  z-index: 10;
  flex-direction: column;
  width: 100%;
  border-bottom: ${(props) =>
    props.position === 'fixed'
      ? '1px solid ' + props.theme.navigation.border_bottom
      : 'none'};

  ${NavItem} {
    text-decoration: none;
    color: ${(props) => props.theme.navigation.items_text};
    background-color: ${(props) => props.theme.navigation.items_background};
  }

  ${NavItem}:hover {
    transition: 0.3s;
    color: ${(props) => props.theme.navigation.items_text_hover};
    background-color: ${(props) =>
      props.theme.navigation.items_background_hover};
  }

  ${NavItem}:visited {
    color: ${(props) => props.theme.navigation.items_text};
  }
`

const ListContainer = styled.div`
  width: 250px;
  background-color: ${(props) => props.theme.navigation.background_sm};
`

const MenuIcon = styled(Menu)`
  color: ${(props) => props.theme.navigation.toggle_button};
  padding: 5px;
`

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${NavItem}:hover {
    transition: 0.3s;
    color: ${(props) => props.theme.navigation.items_text_hover};
    background-color: ${(props) =>
      props.theme.navigation.items_background_hover};
  }

  ${MenuIcon}:hover {
    cursor: pointer;
  }
`

const DrawerLink = styled(Link)``

const StyledList = styled(List)`
  ${DrawerLink} {
    text-decoration: none;
    color: ${(props) => props.theme.navigation.items_text};
    background-color: ${(props) => props.theme.navigation.background};
  }

  ${DrawerLink}:hover {
    transition: 0.3s;
    color: ${(props) => props.theme.navigation.items_text_hover};
    background-color: ${(props) =>
      props.theme.navigation.items_background_hover};
  }

  ${DrawerLink}:visited {
    color: ${(props) => props.theme.navigation.items_text_hover};
  }
`

// Navigation definition

const routes = [
  {
    label: 'Home',
    component: <Home />,
    icon: <House color={'secondary'} />,
    url: '/'
  },
  {
    label: 'Mobile App Development',
    component: <Contact />,
    icon: <PhoneIphone color={'secondary'} />,
    url: '/services/mobile-apps'
  },
  {
    label: 'Simple Websites',
    component: <Contact />,
    icon: <Devices color={'secondary'} />,
    url: '/services/simple-websites'
  },
  {
    label: 'E-Commerce',
    component: <Contact />,
    icon: <ShoppingCart color={'secondary'} />,
    url: '/services/e-commerce'
  },
  {
    label: 'Resumé',
    component: <Resume />,
    icon: <Description color={'secondary'} />,
    url: '/resume'
  },
  {
    label: 'Projects',
    component: <Contact />,
    icon: <Code color={'secondary'} />,
    url: '/resume#projects'
  },
  {
    label: 'Articles',
    component: <Articles />,
    icon: <Assignment color={'secondary'} />,
    url: '/articles'
  },
  {
    label: 'Videos',
    component: <Videos />,
    icon: <YouTube color={'secondary'} />,
    url: '/videos'
  },
  {
    label: 'Contact',
    component: <Contact />,
    icon: <Mail color={'secondary'} />,
    url: '/get-in-touch'
  }
]

const Navigation = ({ selected }) => {
  const theme = useContext(ThemeContext)[0]
  const [open, setOpen] = useState(false)
  const useStyles = makeStyles({
    paper: {
      background: theme.navigation.background_sm
    }
  })
  const styles = useStyles()
  const scrollY = useScrollPosition(60)
  const position = scrollY > 20 ? 'fixed' : 'inherit'

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setOpen(open)
  }

  return (
    <AppTopBar theme={theme} position={position}>
      <Logo src={LogoImage} />
      <Drawer
        classes={{ paper: styles.paper }}
        open={open}
        onClose={toggleDrawer(false)}
      >
        <ListContainer
          theme={theme}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <StyledList theme={theme}>
            {routes.map((route, index) => (
              <DrawerLink to={route.url} key={route.label}>
                <ListItem button>
                  <ListItemIcon>{route.icon && route.icon}</ListItemIcon>
                  <ListItemText primary={route.label} />
                </ListItem>
              </DrawerLink>
            ))}
          </StyledList>
        </ListContainer>
      </Drawer>
      <Container>
        <MenuContainer theme={theme}>
          <Hidden smDown>
            {routes.map((route) => (
              <NavItem smooth key={route.label} to={route.url}>
                {route.label}
              </NavItem>
            ))}
          </Hidden>
          <Hidden mdUp>
            <MenuIcon theme={theme} onClick={toggleDrawer(true)} />
          </Hidden>
        </MenuContainer>
      </Container>
    </AppTopBar>
  )
}

export default Navigation
