import React from 'react'
import styled from 'styled-components'
import { Container } from '@material-ui/core'

// Styled components

const ContentContainer = styled(Container)`
  background-color: white;
`

const Wrapper = ({ children }) => {
  return <ContentContainer>{children}</ContentContainer>
}

export default Wrapper
