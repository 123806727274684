import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
// import TagManager from "react-gtm-module/dist/TagManager";
// const tagManagerArgs = {
//   gtmId: 'G-81WHKXNX8P'
// }
//
// TagManager.initialize(tagManagerArgs)

// <!-- Google tag (gtag.js) -->
// <script async src="https://www.googletagmanager.com/gtag/js?id=G-81WHKXNX8P"></script>
// <script>
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());
//
//   gtag('config', 'G-81WHKXNX8P');
// </script>

// const args = {
//   dataLayer: {
//     event: "page_load"
//     /* can pass more variables here if needed */
//   },
//   dataLayerName: "PageDataLayer"
// };
//
// TagManager.dataLayer(args);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
