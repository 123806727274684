import React, { useContext } from 'react'
import Wrapper from '../Components/Wrapper'
import HeaderImg from '../Asset/Header.jpeg'
import HeaderImgMobile from '../Asset/Website header mobile.jpeg'
import styled from 'styled-components'
import { ThemeContext } from '../Context/ThemeContext'
import TextBox from '../Components/TextBox'
import InfoStrip from './Partials/InfoStrip'
import ImageStrip from './Partials/ImageStrip'
import EcommerceImg from '../Asset/E-Commerce.jpeg'
import SimpleSiteImg from '../Asset/Simple websites.jpeg'
import MobileAppImg from '../Asset/Mobile Apps.jpeg'
import InfoStripImg from '../Asset/Strip.jpeg'
import InfoStripImgMobile from '../Asset/Strip mobile.jpeg'
import Author from './Partials/Author'
import useWindowSize from '../Hooks/useWindowSize'
import { Link } from 'react-router-dom'

// Styled components

const HeaderImage = styled.img`
  width: 100%;
`

const HeaderWrapper = styled.div`
  position: relative;
  top: 0;
  overflow: auto;
  background-color: ${(props) => props.theme.palette.primary};
`

const Row = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Img = styled.img`
  width: 100%;
`

const ReadMore = styled(Link)`
  color: ${(props) => props.theme.link.regular};
  text-decoration: none;
  float: right;

  &:hover {
    color: ${(props) => props.theme.link.hovered};
  }
`

const Home = () => {
  const theme = useContext(ThemeContext)[0]
  const { width } = useWindowSize()

  return (
    <>
      <HeaderWrapper theme={theme}>
        <HeaderImage src={width > 768 ? HeaderImg : HeaderImgMobile} />
      </HeaderWrapper>
      <Wrapper>
        <TextBox width={80} title={'Why Caring?'}>
          <p>
            The stereotype goes that developers speak nerd and understand code
            better than humans. That said, I am the opposite.
          </p>
          <p>
            You may have an idea, a website or a web application that you are
            looking to be built or replaced? I can help you and be your ally to
            achieve your goal. It is much more than coding. It is taking a human
            approach and creating something that your customers and admins will
            love.
          </p>
        </TextBox>
        <Row>
          <TextBox gutter={5} align={'left'} size={'small'}>
            <Img src={EcommerceImg} />
            <p>
              Imagine what you can achieve by owning a fast and reliable online
              store that customers like and recommend to others. I build fully
              customized online stores that stand out. They are using cutting
              edge tech that works exactly the way you’d expect.
            </p>
            <ReadMore theme={theme} to={'/services/e-commerce'}>
              Read more
            </ReadMore>
          </TextBox>
          <TextBox gutter={5} align={'left'} size={'small'}>
            <Img src={MobileAppImg} />
            <p>
              To step up the game, you can reach customers via your very own
              unique cross-platform mobile application. It will look great, be
              fast and can have all the features that come to mind. I take care
              of the building and distribution for you through the app stores.
            </p>
            <ReadMore theme={theme} to={'/services/mobile-apps'}>
              Read more
            </ReadMore>
          </TextBox>
          <TextBox gutter={5} size={'small'} align={'left'}>
            <Img src={SimpleSiteImg} />
            <p>
              Simple yet powerful. These are the sites that need to look pretty
              and have no need for an extensive admin area. Forget Wordpress! It
              isn’t cheap to maintain, nor fast or easy to work with. You’ll be
              amazed by the speed and simplicity of getting your word out there
              guaranteed.{' '}
            </p>
            <ReadMore theme={theme} to={'/services/simple-websites'}>
              Read more
            </ReadMore>
          </TextBox>
        </Row>
      </Wrapper>
      <InfoStrip>
        <Img src={width > 900 ? InfoStripImg : InfoStripImgMobile} />
      </InfoStrip>
      <Wrapper>
        <Author />
        <ImageStrip />
      </Wrapper>
    </>
  )
}

export default Home
