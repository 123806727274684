import React, { useState } from 'react'
import defaultTheme from '../Config/themes/default.json'
import GlobalStyles from '../Config/globalStyles'

const ThemeContext = React.createContext()

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(defaultTheme) // Let's leave the possibility open to update the theme on the fly

  return (
    <ThemeContext.Provider value={[theme, setTheme]}>
      <GlobalStyles />
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeProvider }
